import React from 'react';

import { analyticsEvent } from '@/src/analytic_tag_manager';

type Props = {
  highlightedText?: any;
  shortName: string;
  label: string;
};

const SpecialityItem = ({ highlightedText, shortName, label }: Props) => (
  <div
    className={`search-item item--spe item-${shortName}`}
    onClick={() => {
      analyticsEvent({
        category: 'SearchForm',
        action: 'ClickSpecialty',
        label: shortName,
      });
    }}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: highlightedText.join(' ') || label,
      }}
    />
  </div>
);

export default SpecialityItem;
