import React from 'react';
import { snakeCase } from 'lodash';
import Avatar from '../../atoms/Avatar/Avatar';
import { analyticsEvent } from '@/src/analytic_tag_manager';

type Props = {
  pictureS3Id?: string;
  objectID: string;
  city: string;
  isTlcAvailable?: boolean;
  highlightedText?: any;
  name: string;
};

const CenterItem = ({
  pictureS3Id,
  objectID,
  isTlcAvailable,
  city,
  highlightedText,
  name,
}: Props) => {
  const avatarSource = pictureS3Id
    ? `/files/${pictureS3Id}`
    : '/static/images/default_center.svg';
  return (
    <div
      className={`search-item item--center item-${objectID}`}
      data-cy={`suggestion-center-${snakeCase(name)}`}
      onClick={() => {
        analyticsEvent({
          category: 'Search',
          action: 'Search',
          label: 'center',
        });
      }}
    >
      <Avatar source={avatarSource} isOnline={isTlcAvailable} />
      <div className="wrapper-info">
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: highlightedText.join(' '),
          }}
        />
        <p className="info">
          <span>{`${city}`}</span>
        </p>
      </div>
    </div>
  );
};

export default CenterItem;
